import React, { Component } from 'react';

class Extra extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.full_name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="extra">
      <div className="row">
      <h1>Other stuff:</h1>
         <div className="nine columns main-col">
               <h2>What I'm Reading:</h2>

               {/* <p>{book}</p> */}
               <p> Book</p>
            </div>
            <div className="three columns">
               <img className="pic"
                  src={profilepic} alt="Kamran Profile Pic" 
                  onMouseOver={() => console.log('click')} 
               />
            </div>
      </div>

   </section>
    );
  }
}

export default Extra;
